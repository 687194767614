import React, { useState } from "react";
import PropTypes from "prop-types";

import { favoriteValidator } from "../schemas/favorite";
import FormInputs from "../../Components/FormInputs";
import { groupBy } from "../../Components/FormInputs.utils";
import { isCompanyBask } from "../utils";

import "./FavoriteForm.css";

const FavoriteForm = (props) => {
  const {
    mode,
    defaultFormData = {},
    compareTo,
    visitTypes = [],
    pharmacies = {},
    onSubmit,
    config,
    errorMessage,
    company,
    customSubmitText,
  } = props;

  defaultFormData.quantity = defaultFormData.quantity ?? 0;
  defaultFormData.refills = defaultFormData.refills ?? 0;
  defaultFormData.daysSupply = defaultFormData.daysSupply ?? 0;
  defaultFormData.pharmacyId = defaultFormData.pharmacyId ?? "";
  const [formData, setFormData] = useState(defaultFormData);

  const handleChange = (newFormData) => {
    setFormData(newFormData);
  };

  const modeText = {
    edit: "Request update favorite",
    create: "Request create favorite",
  };

  const { isValidated, errors } = favoriteValidator.validate(formData);

  const mappedPharmacies = Object.keys(pharmacies).map((key) => ({
    value: key,
    label: pharmacies[key],
  }));
  const mappedVisitTypes = visitTypes
    .filter(
      (type) =>
        !["weightlossfollowup", "jaseCaseCanada", "jaseCaseJase"].includes(type)
    )
    .map((item) => ({ value: item }));

  const isMed = formData.type && formData.type === "med" ? true : false;

  const defaultConfigFields = [
    {
      name: "favoriteName",
      text: "Favorite Name",
      required: true,
      locked: false,
    },
    isCompanyBask(company) && {
      name: "baskName",
      text: "Bask NDC",
      required: isCompanyBask(company),
      locked: false,
    },
    !isCompanyBask(company) && {
      name: "clientName",
      // name: "medId",
      text: "MedId",
      required: true,
      locked: true,
    },
    { name: "medication", text: "Medication", required: true },
    {
      name: "type",
      text: "Type",
      required: true,
      type: "select",
      options: [{ value: "compound" }, { value: "med" }],
    },
    isMed && { name: "strength", text: "Strength" },
    { name: "sig", text: "SIG", required: true },
    { name: "quantity", text: "Quantity", required: true, type: "number" },
    {
      name: "dispenseUnit",
      text: "Dispense Unit",
      required: true,
      type: "select",
      options: [
        { value: "capsule" },
        { value: "each" },
        { value: "gram" },
        { value: "ounce" },
        { value: "kit" },
        { value: "milligram" },
        { value: "milliliter" },
        { value: "packet" },
        { value: "tablet" },
        { value: "troche" },
        { value: "applicator" },
      ],
    },
    { name: "refills", text: "Refills", required: true, type: "number" },
    {
      name: "daysSupply",
      text: "Days Supply",
      required: true,
      type: "number",
    },
    { name: "pharmacyNotes", text: "Pharmacy Notes", type: "textarea" },
    {
      name: "visitType",
      text: "Visit Type",
      type: "select",
      options: mappedVisitTypes,
    },
    {
      name: "pharmacyId",
      text: "Pharmacy Id",
      type: "creatableSelect",
      options: mappedPharmacies,
    },
  ].filter((field) => !!field);

  const formConfig = config ?? groupBy(defaultConfigFields, 2);

  const handleSubmitFavorite = async (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const isBaskWithoutBaskName = isCompanyBask(company) && !formData.baskName;

  return (
    <>
      <span className="formError">{errorMessage}</span>
      <form
        onSubmit={handleSubmitFavorite}
        className="formStyle favorites-form"
      >
        <FormInputs
          config={formConfig}
          formData={formData}
          compareTo={compareTo}
          onChange={handleChange}
          errors={errors}
          mode={mode}
        />
        <div className="formRowRight">
          <button
            type="submit"
            className="formButton"
            disabled={!isValidated || isBaskWithoutBaskName}
          >
            {customSubmitText || modeText[mode]}
          </button>
        </div>
      </form>
    </>
  );
};

FavoriteForm.propTypes = {
  mode: PropTypes.string.isRequired,
  defaultFormData: PropTypes.object,
  compareTo: PropTypes.object,
  visitTypes: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  config: PropTypes.array,
  errorMessage: PropTypes.string,
};

export default FavoriteForm;
